const base_api_url = process.env.NODE_ENV === "development" ? "https://vetexpert.dev:8081/checkup-api" :
    "https://c.vetexpert.ru/checkup-api";

const makeAppointmentUrl = `${base_api_url}/make_appointment`;
const getBusyTimeslotsUrl = `${base_api_url}/get_busy_timeslots`;
const uploadFilesUrl = `${base_api_url}/upload_files/`;
const getClientByTokenUrl = `${base_api_url}/get_client`;

export {
  makeAppointmentUrl, getBusyTimeslotsUrl, uploadFilesUrl, getClientByTokenUrl
};
