import React, { useState, useRef } from "react";

import {FormField, RemoveFileIcon, FilesList} from "./filesUpload.styles";

const  convertBytesSizeToStr= (bytes) => {
  let convertedSize = Math.round(bytes / 1024);
  let sizeUnits = 'КБ';
  if (convertedSize > 1000) {
    convertedSize = Math.round(convertedSize / 1024);
    sizeUnits = 'МБ';
  }
  return convertedSize.toString() + ' ' + sizeUnits;
}

const FilesUpload = (
  props
) => {
  const fileInputField = useRef(null);

  const [files, setFiles] = useState({});

  const handleUploadButtonClick = () => {
    fileInputField.current.click();
  };

  const convertNestedObjectToArray = (nestedObj) =>
    Object.keys(nestedObj).map((key) => nestedObj[key]);

  const callUpdateFilesCallback = (files) => {
    const filesArray = convertNestedObjectToArray(files);
    props.updateFilesCallback(filesArray);
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      files[file.name] = file;
    }
    return {...files}
  };

  const handleNewFileUpload = (event) => {
    const { files: newFiles } = event.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCallback(updatedFiles);

    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({...files});
    callUpdateFilesCallback({...files});
  };

  return (
    <React.Fragment>
      <section>
        <button type="button" className="ew-button ew-button_color-secondary mt-8"
        onClick={handleUploadButtonClick}>
          <div tabIndex="-1" className="ew-button__inner" >
            <span>Добавить файлы</span>
          </div>
        </button>
        <FormField
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          multiple
          {...props}
        />
      </section>
      <article>
        <section className="mt-8">
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName]
            return (
              <section key={fileName} className="mt-4">
                <div>
                  <span>{file.name} </span>
                  <span>
                    <span>({convertBytesSizeToStr(file.size)}) </span>
                    <RemoveFileIcon
                      className="fas fa-trash-alt"
                      onClick={() => removeFile(fileName)}
                    />
                  </span>
                </div>
              </section>
            )
          })}
        </section>
      </article>
    </React.Fragment>
  )
};

export default FilesUpload;
