import React from 'react'

function TimeSlot(props) {
    const onClick = (slot) => {
        if (props.disabled) {return}
        props.onSelect(slot)
    }
    return (
        <label className={"ew-calendar__spot " 
                          + (props.selected ? " ew-calendar__spot_active " : "")
                          + (props.disabled ? " ew-calendar__spot_disabled " : "")}
               onClick={() => onClick(props.slot)}>
            <input type="radio" name="slot" className="sr-only" value={props.slot} />
            {props.slot}
        </label>
    )
}

export default TimeSlot