import React from 'react'
import {DateTime} from 'luxon'
import {weekdayToShortRussian, monthToShortRussian} from './dateUtils'

function DaySelector(props){
    return (
        <span className="ew-calendar__spacer" onClick={() => props.onSelectDay(props.day)}>
            <label className={"ew-calendar__day " + (props.selected ? "ew-calendar__day_active" : "")}>
                <input type="radio" name="date" className="sr-only" value={DateTime.fromJSDate(props.day).toFormat("yyyy-MM-dd")} />
                <span className="ew-calendar__weekday">{weekdayToShortRussian(props.day)}</span>
                <span className="ew-calendar__date">{props.day.getDate()}</span>
                <span className="ew-calendar__month">{monthToShortRussian(props.day)}</span>
            </label>
        </span>
    )
}

export default DaySelector