import React, { useState } from 'react'
import PhoneInput from './PhoneInput'
import FilesUpload from "./FilesUpload";

function ClientForm(props){
    const _MAX_TEXTAREA_LENGTH = 272;

    const [pet, setPet] = useState({
        pet_type_id: '',
        nickname: '',
        sex: '',
        breed: '',
        years: '',
        months: '',
        feeding_id: '',
        castration_status: '',
        vaccination_status: '',
        parasite_treatment: '',
        diseases: '',
        complaints: ''
    })

    const petTypes = [
        {name: "Собака", id: 25},
        {name: "Кошка", id: 26}
    ]
    const petSex = [
        {name: "Мужской", id: "м"},
        {name: "Женский", id: "ж"}
    ]
    const petFeeding = [
        {name: "Домашнее", id: 1},
        {name: "Коммерческое", id: 2},
        {name: "Смешанное", id: 3}
    ]
    const castrationStatus = [
        {name: "Кастрирован", id: true},
        {name: "Не кастрирован", id: false}
    ]
    const vaccinationStatus = [
        {name: "Полная вакцинация", id: 1},
        {name: "Частичная вакцинация", id: 2},
        {name: "Не вакцинирован", id: 3}
    ]
    const parasiteStatus = [
        {name: "Да, полная", id: 1},
        {name: "Не обработан", id: 2},
        {name: "Только от эктопаразитов", id: 3},
        {name: "Только от эндопаразитов", id: 4},
    ]
    const setClientField = field => event => {
        let newClientData = {...props.clientData, [field]: event.target.value}
        props.onChangeClient(newClientData)
    }
    const setPetField = field => event => {
        const newPetData = {...pet, [field]: event.target.value}
        setPet(newPetData)
        props.onChangePet(newPetData)
    }

    const updateUploadedFiles = (files) => {
        props.onUpdateFiles(files)
    }

    const callUpdateUploadedFiles = (files) => {
        updateUploadedFiles(files)
    }

    return (
        <div>
            <div className="ew-customer-fields mt-8">
                <label className="flex mt-4 aw-text-field is-string has-label" htmlFor="input-firstName">
                    <div className="relative w-full">
                        <span className="input-label">Имя</span>
                        <input type="string" value={props.clientData.firstName} onChange={setClientField("firstName")}
                               required="required"
                               className={"aw-text-field__element p-3 " + (props.formErrors.firstName ? "incorrect-input-value" : "")}
                               id="input-firstName"/>
                    </div>
                </label>
                <label className="flex mt-4 aw-text-field is-string has-label" htmlFor="input-email">
                    <div className="relative w-full">
                        <span className="input-label">Email</span>
                        <input type="string" value={props.clientData.email} onChange={setClientField("email")}
                               className={"aw-text-field__element p-3 " + (props.formErrors.email ? "incorrect-input-value" : "")}
                               id="input-email"/>
                    </div>
                </label>
                <label className="flex mt-4 aw-text-field is-string has-label" htmlFor="input-phone">
                    <div className="relative w-full">
                        <span className="input-label">Телефон</span>
                        <PhoneInput id="input-phone" value={props.clientData.phone} onChange={setClientField("phone")}
                                    invalid={props.formErrors.phone}/>
                    </div>
                </label>
                {/* Чекбокс пока не удаляем — может пригодиться его вёрстка, когда попросят добавить оферту какую-нибудь */}
                <div className="aw-switch-field is-checkbox mt-4 fb-field">
                    <input type="checkbox"
                           value="voice"
                           id="voiceCommunicationMethod"
                           className="aw-switch-field__element"
                           checked={props.clientData.communicationMethod === "voice"}
                           onChange={setClientField("communicationMethod")}/>
                    <label className="aw-switch-field__label" htmlFor="voiceCommunicationMethod">
                        <span>Аудио консультация</span>
                    </label>
                </div>
                <div className="aw-switch-field is-checkbox mt-4 fb-field">
                    <input type="checkbox"
                           value="video"
                           id="videoCommunicationMethod"
                           className="aw-switch-field__element"
                           checked={props.clientData.communicationMethod === "video"}
                           onChange={setClientField("communicationMethod")}/>
                    <label className="aw-switch-field__label" htmlFor="videoCommunicationMethod">
                        <span>Видео консультация (рекомендуем)</span>
                    </label>
                </div>

                <div className="client-survey">
                    <div className="survey-header">Для того, чтобы ветеринар смог заранее подготовиться к онлайн-чекапу,
                        заполните данные о своем питомце:
                    </div>
                    <label className="flex mt-4 aw-text-field is-string has-label" htmlFor="select-pet-type">
                        <div className="relative w-full">
                            <div className="input-label">Вид животного</div>
                            <select id="select-pet-type" value={pet.pet_type_id}
                                    className={"aw-text-field__element p-3 " +
                                    (props.formErrors.petTypeId ? "incorrect-input-value" : "")}
                                    onChange={setPetField("pet_type_id")}>
                                <option value="" >Выберите вид питомца</option>
                                {petTypes.map(petType => (
                                    <option key={petType.name} value={petType.id}>{petType.name}</option>
                                ))}
                            </select>
                        </div>
                    </label>
                    <label className="flex mt-4 aw-text-field is-string has-label" htmlFor="input-nickname">
                        <div className="relative w-full">
                            <span className="input-label">Кличка</span>
                            <input type="string" value={pet.nickname}
                                   onChange={setPetField("nickname")}
                                   required="required"
                                   className={"aw-text-field__element p-3 " + (props.formErrors.firstName ? "incorrect-input-value" : "")}
                                   id="input-firstName"/>
                        </div>
                    </label>
                    <label className="flex mt-4 aw-text-field is-string has-label"
                           htmlFor="select-pet-sex">
                        <div className="relative w-full">
                            <div className="input-label">Пол</div>
                            <select id="select-pet-sex" value={pet.sex}
                                    className={"aw-text-field__element p-3 " +
                                    (props.formErrors.sex ? "incorrect-input-value" : "")}
                                    onChange={setPetField("sex")}>
                                <option value="" >Выберите пол питомца</option>
                                {petSex.map(sex => (
                                  <option key={sex.name} value={sex.id}>{sex.name}</option>
                                ))}
                            </select>
                        </div>
                    </label>
                    <label className="flex mt-4 aw-text-field is-string has-label" htmlFor="input-breed">
                        <div className="relative w-full">
                            <span className="input-label">Порода</span>
                            <input type="string" value={pet.breed}
                                   onChange={setPetField("breed")}
                                   required="required"
                                   className={"aw-text-field__element p-3 " + (props.formErrors.breed ? "incorrect-input-value" : "")}
                                   id="input-breed"/>
                        </div>
                    </label>
                    <div className="flex mt-4 aw-text-field is-string has-label" >
                        <div className="relative w-full">
                            <span className="input-label">Возраст</span>
                        </div>
                    </div>
                    <label className="flex mt-4 aw-text-field is-string has-label" htmlFor="input-age">
                        <div className="relative w-full">
                            <span className="input-label">лет</span>
                            <input type="number" value={pet.years}
                                   onChange={setPetField("years")}
                                   required="required"
                                   className={"aw-text-field__element p-3 " + (props.formErrors.years ? "incorrect-input-value" : "")}
                                   id="input-age"/>
                        </div>
                    </label>
                    <label className="flex mt-4 aw-text-field is-string has-label" htmlFor="input-months">
                        <div className="relative w-full">
                            <span className="input-label">месяцев</span>
                            <input type="number" value={pet.months}
                                   onChange={setPetField("months")}
                                   required="required"
                                   className={"aw-text-field__element p-3 " + (props.formErrors.months ? "incorrect-input-value" : "")}
                                   id="input-months"/>
                        </div>
                    </label>
                    <label className="flex mt-4 aw-text-field is-string has-label" htmlFor="select-pet-feeding">
                        <div className="relative w-full">
                            <div className="input-label">Кормление</div>
                            <select id="select-pet-feeding" value={pet.feeding_id}
                                    className={"aw-text-field__element p-3 " +
                                    (props.formErrors.feeding ? "incorrect-input-value" : "")}
                                    onChange={setPetField("feeding_id")}>
                                <option value="" >Выберите тип кормления</option>
                                {petFeeding.map(feeding => (
                                  <option key={feeding.name} value={feeding.id}>{feeding.name}</option>
                                ))}
                            </select>
                        </div>
                    </label>
                    <label className="flex mt-4 aw-text-field is-string has-label"
                           htmlFor="select-castration-status">
                        <div className="relative w-full">
                            <div className="input-label">Статус кастрации</div>
                            <select id="select-castration-status" value={pet.castration_status}
                                    className={"aw-text-field__element p-3 " +
                                    (props.formErrors.castration ? "incorrect-input-value" : "")}
                                    onChange={setPetField("castration_status")}>
                                <option value="" >Выберите статус кастрации</option>
                                {castrationStatus.map(status => (
                                  <option key={status.name} value={status.id}>{status.name}</option>
                                ))}
                            </select>
                        </div>
                    </label>
                    <label className="flex mt-4 aw-text-field is-string has-label"
                           htmlFor="select-vaccination-status">
                        <div className="relative w-full">
                            <div className="input-label">Статус вакцинации</div>
                            <select id="select-vaccination-status"
                                    value={pet.vaccination_status}
                                    className={"aw-text-field__element p-3 " +
                                    (props.formErrors.vaccination ? "incorrect-input-value" : "")}
                                    onChange={setPetField("vaccination_status")}>
                                <option value="" >Выберите статус вакцинации</option>
                                {vaccinationStatus.map(status => (
                                  <option key={status.name} value={status.id}>{status.name}</option>
                                ))}
                            </select>
                        </div>
                    </label>
                    <label className="flex mt-4 aw-text-field is-string has-label"
                           htmlFor="select-parasite-status">
                        <div className="relative w-full">
                            <div className="input-label">Обработка от экто- и эндопаразитов</div>
                            <select id="select-parasite-status"
                                    value={pet.parasite_treatment}
                                    className={"aw-text-field__element p-3 " +
                                    (props.formErrors.parasiteTreatment ? "incorrect-input-value" : "")}
                                    onChange={setPetField("parasite_treatment")}>
                                <option value="" >Выберите статус обработки от паразитов</option>
                                {parasiteStatus.map(status => (
                                  <option key={status.name} value={status.id}>{status.name}</option>
                                ))}
                            </select>
                        </div>
                    </label>
                    <label className="flex mt-4 aw-text-field is-string has-label"
                           htmlFor="input-diseases">
                        <div className="relative w-full">
                            <span className="input-label">Перенесённые травмы / заболевания</span>
                            <textarea value={pet.diseases}
                                   onChange={setPetField("diseases")}
                                   required="required"
                                   className={"aw-text-field__element p-3 text-area " + (props.formErrors.diseases ? "incorrect-input-value" : "")}
                                   maxLength={_MAX_TEXTAREA_LENGTH}
                                   id="input-diseases"
                                   rows="5"
                            />
                        </div>
                    </label>
                    <label className="flex mt-4 aw-text-field is-string has-label"
                           htmlFor="input-complaints">
                        <div className="relative w-full">
                            <span className="input-label">Текущие жалобы</span>
                            <textarea value={pet.complaints}
                                      onChange={setPetField("complaints")}
                                      required="required"
                                      className={"aw-text-field__element p-3 text-area " + (props.formErrors.complaints ? "incorrect-input-value" : "")}
                                      maxLength={_MAX_TEXTAREA_LENGTH}
                                      id="input-complaints"
                                      rows="5"
                            />
                        </div>
                    </label>
                    <div className="survey-header mt-8">Если есть медицинские (ветеринарные) документы или фотографии
                        питомца, которые помогут ветеринару сложить мнение о здоровье вашего питомца, то прикрепите их ниже:
                    </div>
                    <FilesUpload updateFilesCallback={callUpdateUploadedFiles}/>
                </div>
            </div>
        </div>
    )
}

export default ClientForm
