import styled from "styled-components";

export const FormField = styled.input`
  display: none;
  // border: none;
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // opacity: 0;
  
  // &:focus {
  //   outline: none;
  // }
`;

export const RemoveFileIcon = styled.i`
  cursonr: pointer;
  padding: 0 0 0 20px;
  
  &:hover {
    transform: scale(1.3);
  }
`;

export const FilesList = styled.section`
`