import React, {useState, useEffect} from 'react'
import {DateTime} from 'luxon'

import ClientForm from './ClientForm'
import {buildDays, weekdayToFullRussian,
  monthToFullRussianVersion, buildAllEverydaySlots} from './dateUtils'
import DaySelector from './DaySelector'
import TimeSlot from './TimeSlot'
import {makeAppointmentUrl, getBusyTimeslotsUrl, uploadFilesUrl,
  getClientByTokenUrl} from './config'


function App() {
  let [days, setDays] = useState([])
  let [daySelected, setDaySelected] = useState(null)
  let [slotSelected, setSlotSelected] = useState(null)
  let [busyTimeSlots, setBusyTimeSlots] = useState([])
  let [slotsFromDatetime, setSlotsFromDatetime] = useState(null)
  let [client, setClient] = useState({
      firstName: '',
      email: '',
      phone: '',
      communicationMethod: 'video',
      id: ''
  })
  let [pet, setPet] = useState({
      pet_type_id: '',
      nickname: '',
      sex: '',
      breed: '',
      years: '',
      months: '',
      feeding_id: '',
      castration_status: '',
      vaccination_status: '',
      parasite_treatment: '',
      diseases: '',
      complaints: ''
  })
  const errorsBase = {
      firstName: '',
      email: '',
      phone: '',
      slot: '',
      nickname: '',
      petTypeId: '',
      sex: '',
      breed: '',
      years: '',
      months: '',
      feeding: '',
      castration: '',
      vaccination: '',
      parasiteTreatment: '',
      diseases: '',
      complaints: ''
  }
  let [formErrors, setFormErrors] = useState(errorsBase)
  const [saveStatus, setSaveStatus] = useState(null)
  const [scheduled, setScheduled] = useState(false)
  const [clientTokenError, setClientTokenError] = useState(true);
  const [submitButtonActive, setSubmitButtonActive] = useState(true)
  const [filesToUpload, setFilesToUpload] = useState([])

  const reload = () => {
    window.location.reload(false)
  }

  const everydayTimeSlots = buildAllEverydaySlots()
  
  const onSelectDay = (day) => {
    setSlotSelected(null)
    setDaySelected(day)
  }

  const onSelectSlot = (slot) => {
    setSlotSelected(slot)
  }
  
  const onChangeClient = client => {
    setClient(client)
  }
  const onChangePet = pet => {
    setPet(pet)
  }
  const UpdateFiles = files => {
    setFilesToUpload(files)
  }
  const validateData = () => {
    const errors = {}
    if (!client.firstName){
      errors["firstName"] = 'Укажите Ваше имя'
    }
    if (!client.email){
      errors["email"] = 'Укажите Ваше Email'
    }
    if (!client.phone){
      errors["phone"] = 'Укажите Ваш телефон'
    }
    if (!pet.nickname){
      errors["nickname"] = 'Укажите кличку питомца'
    }
    if (!pet.pet_type_id){
      errors["petTypeId"] = 'Выберите вид питомца'
    }
    if (!pet.sex){
      errors["sex"] = 'Выберите пол питомца'
    }
    if (!daySelected || !slotSelected){
      errors["slot"] = 'Выберите, пожалуйста, день и время консультации'
    }
    if (!pet.breed){
      errors["breed"] = 'Укажите породу питомца'
    }
    if (!pet.years){
      errors["years"] = 'Укажите возраст питомца (лет)'
    }
    if (!pet.months){
      errors["months"] = 'Укажите возраст питомца (месяцев)'
    }
    if (!pet.feeding_id){
      errors["feeding"] = 'Укажите тип кормления'
    }
    if (!pet.castration_status) {
      errors["castration"] = 'Выберите статус кастрации'
    }
    if (!pet.vaccination_status) {
      errors["vaccination"] = 'Выберите статус вакцинации'
    }
    if (!pet.parasite_treatment) {
      errors["parasiteTreatment"] = 'Выберите статус обработки от паразитов'
    }
    if (Object.keys(errors).length){
      setFormErrors({...errorsBase, ...errors})
      return false
    }
    setFormErrors({errorsBase})
    return true
  }
  const getDaySelectedFormatted = () => {
    return DateTime.fromJSDate(daySelected).toFormat("dd.MM.yyyy")
  }
  const createAppointment = (attachmentId) => {
    let jsonData = {
      "client": {
        "phone": client.phone,
        "email": client.email,
        "first_name": client.firstName,
        "id": client.id
      },
      "pet": {
        "pet_type_id": pet.pet_type_id,
        "nickname": pet.nickname,
        "sex": pet.sex,
        "breed": pet.breed,
        "years": pet.years,
        "months": pet.months,
        "feeding_id": pet.feeding_id,
        "castration_status": pet.castration_status,
        "vaccination_status": pet.vaccination_status,
        "parasite_treatment": pet.parasite_treatment,
        "diseases": pet.diseases,
        "complaints": pet.complaints
      },
      "appointment": {
        "appointment_time": `${DateTime.fromJSDate(daySelected).toFormat("yyyy-MM-dd")} ${slotSelected}:00`,
        "communication_method": client.communicationMethod
      },
      "attachment_id": attachmentId
    }
    fetch(makeAppointmentUrl, {
      method: "POST",
      mode: "cors",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(jsonData)
    }).then(response => response.json()).then(json => {
      if (json.success) {
        setSaveStatus(true)
      } else {
        setSubmitButtonActive(true);
        if (json.message && !json.message.includes("ValidationError")){
          alert(json.message)
        } else if (json.message.includes("ValidationError")) {
          alert("Пожалуйста, убедитесь в корректности заполнения данных формы")
        } else {
          alert("Заявка не сохранена. Пожалуйста, проверьте введённые данные или обновите страницу.")
        }
      }
    })
  }
  const createAppointmentWithAttachments = () => {
    setSubmitButtonActive(false)
    if (!validateData()) {
      setSubmitButtonActive(true)
      return
    }

    const files_data = new FormData();
    if (filesToUpload.length) {
      for (const file of filesToUpload) {
        files_data.append('files[]', file, file.name);
      }
      fetch(uploadFilesUrl, {
        method: 'POST',
        body: files_data
      }).then(response => response.json())
        .then(json => {
          if (json.success) {
            const attachmentId = json.attachment_id
            createAppointment(attachmentId)
          } else {
            console.log('upload files error')
          }
        })
    } else {
      createAppointment('')
    }
  }

  useEffect(() => {
    const windowUrl = window.location.search;
    const getParams = new URLSearchParams(windowUrl);
    const clientToken =getParams.get("token")
    if (!clientToken) {
      return
    }
    setClientTokenError(false)
    fetch(`${getClientByTokenUrl}?token=${clientToken}`)
      .then(response => response.json())
      .then(json => {
        if (json.success) {
          const clientData = {
            firstName: json.data.firstname,
            email: json.data.email,
            phone: json.data.phone,
            id: json.data.id
          }
          setClient({...client, ...clientData})
          if (json.data.consultation_scheduled) {
            setScheduled(true)
          }
        } else {
          setClientTokenError(true)
        }
      })
    fetch(getBusyTimeslotsUrl)
      .then(response => response.json())
      .then(json => {
        let [slotsFromDatetime, slotsToDatetime] = [json.show_slots_from, json.show_slots_to]
          .map(d => DateTime.fromFormat(d, 'yyyy-MM-dd HH:mm:ss').toJSDate())
        setSlotsFromDatetime(slotsFromDatetime)
        const days = buildDays(slotsFromDatetime, slotsToDatetime)
        setDays(days)
        setDaySelected(days[0])
        let _busyTimeSlots = []
        for (let day in json.busy_timeslots){
          for (let slot of json.busy_timeslots[day]){
            _busyTimeSlots.push(`${day} ${slot}`)
          }
        }
        setBusyTimeSlots(_busyTimeSlots)
      });
  }, [])
  return (
    <div className="App">
      {
        (clientTokenError || scheduled) ? <div/> :
        (!saveStatus) ?
        (<div className="ew-calendar">
          <div className="ew-calendar__selected">
            {/* кнопка листалка влево — пока выключаем, даты не скроллим */}
            {/*<button style="display: none;" disabled="disabled" type="button" className="ew-button ew-button_color-transparent ew-calendar__selected-nav-button">
              <span tabindex="-1" className="ew-button__inner">
                <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" aria-hidden="true" className="ew-icon">
                  <path d="M4 5l6 6 6-6 2 2-8 8-8-8 2-2z" style="transform: rotate(90deg);"></path>
                </svg>
              </span>
            </button>*/}
            {daySelected && <div className="today-in-calendar">
              {weekdayToFullRussian(daySelected)}, {daySelected.getDate()} {monthToFullRussianVersion(daySelected).toLocaleLowerCase()}
            </div>}
            {/* кнопка листалка вправо — пока выключаем, даты не скроллим */}
            {/*<button style="display: none" type="button" className="ew-button ew-button_color-transparent ew-calendar__selected-nav-button">
              <span tabindex="-1" className="ew-button__inner">
                <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" aria-hidden="true" className="ew-icon">
                  <path d="M4 5l6 6 6-6 2 2-8 8-8-8 2-2z" style="transform: rotate(-90deg);"></path>
                </svg>
              </span>
            </button>*/}
            </div>
            
            <div className="aw-slider ew-calendar__days">
              <span className="aw-slider__scroller">

                {days.map((day, index) => 
                  <DaySelector key={index} day={day} selected={day === daySelected} onSelectDay={onSelectDay} />
                )}

              </span>
              {/*<span aria-hidden="true" className="aw-slider__shadow aw-slider__shadow_left" style={{opacity: 0}}></span>
              <span aria-hidden="true" className="aw-slider__shadow aw-slider__shadow_right" style={{opacity: 1, display: 'none'}}></span>*/}
            </div>
            <div className="ew-calendar__spots">
              {/*<label className="ew-calendar__spot ew-calendar__spot_active"><input type="radio" name="time" className="sr-only" value="08:00-08:50" checked="" />08:00</label>
              <label className="ew-calendar__spot ew-calendar__spot_disabled"><input type="radio" name="time" className="sr-only" value="08:15-09:05" />08:15</label>
              <label className="ew-calendar__spot"><input type="radio" name="time" className="sr-only" value="08:30-09:20" />08:30</label> */}
              { everydayTimeSlots.map(slot => {
                  // Для сегодняшнего дня не выводим уже прошедшие слоты
                  if (!slotsFromDatetime || !daySelected) return null
                  if (slotsFromDatetime.toDateString() === daySelected.toDateString()) {
                    if (slot < DateTime.fromJSDate(slotsFromDatetime).toFormat("HH:mm")) {
                        return null
                    }
                  }

                  return <TimeSlot
                    key={slot}
                    slot={slot}
                    daySelected={daySelected}
                    selected={slot === slotSelected}
                    onSelect={onSelectSlot}
                    disabled={busyTimeSlots.includes(DateTime.fromJSDate(daySelected).toFormat("yyyy-MM-dd") + " " + slot)} />
              }) }
            </div>
            
            <ClientForm onChangeClient={onChangeClient}
                        onChangePet={onChangePet}
                        onUpdateFiles={UpdateFiles}
                        clientData={client}
                        formErrors={formErrors} />

            { formErrors.slot ? <div style={{margin: '25px 0 0 0', color: 'red'}}>{formErrors.slot}</div> : null }

            <button type="submit" disabled={submitButtonActive ? "" : "disabled"} className="ew-button ew-button_color-secondary mt-8 w-full" onClick={createAppointmentWithAttachments}>
                <span tabIndex="-1" className="ew-button__inner">Записаться на чекап</span>
            </button>
            <div className="pers">
                Нажимая «Оформить запись», Вы соглашаетесь с <a href="/lk_pers.pdf" target="_blank">политикой обработки персональных данных</a>
            </div>
        </div>) : (<div style={{margin: '2rem 0 2rem 0', fontSize: '1.3em', textAlign: 'center'}}>
            Вы успешно записаны на {getDaySelectedFormatted()} в {slotSelected}!
            Всю подробную информацию и ссылку на консультацию мы выслали вам на почту. До встречи!</div>)
      }
    </div>
  );
}

export default App
